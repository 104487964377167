import { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'components/Contentful'
//import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import useMobileNavState from 'hooks/useMobileNavState'
import useActiveNavItemIndex from 'hooks/useActiveNavItemIndex'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import useIsSsr from 'hooks/useIsSsr'
import SubNavMenu from './SubNavMenu'
import styles from './Navigation.module.scss'
import ChevronDown from 'components/Svgs/ChevronDown.js'
import classNames from 'classnames'

const condensedHeight = 0

/**
 * @param {{
 *   disabledRoutes: []
 *  currentPathname: string
 *  itemIndex: number
 *  label: string
 *  subNavMenus: ?[]
 *  testid: string
 *  url: ?string
 * }} props
 */
export default function MainNavItem({
  currentPathname,
  itemIndex,
  label,
  subNavMenus,
  testid,
  url,
  disabledRoutes,
  disabledElements,
}) {
  const titleRef = useRef(null)
  const subNavRef = useRef(null)

  const [activeIndex, setActiveIndex] = useActiveNavItemIndex()
  const [, setMobileNavOpen] = useMobileNavState()

  // Tracks the expanded height of the mobile sub nav
  const [expandedHeight, setExpandedHeight] = useState(null)

  const hasSubMenus = Boolean(subNavMenus && subNavMenus.length)
  const isActive = activeIndex === itemIndex
  const isSsr = useIsSsr()

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  // Effect captures the height of the items expanded state. Re-fire when either
  // refs or the number of sub menus change
  useEffect(() => {
    if (!isMobile) {
      // Clear the mobile item heights when the window size is desktop
      expandedHeight && setExpandedHeight(null)
      return
    }

    // Set the expanded height if one isn't saved yet
    !expandedHeight && setExpandedHeight(subNavRef?.current?.offsetHeight)
  }, [expandedHeight, itemIndex, titleRef, subNavRef, hasSubMenus, isMobile])

  /**
   * Handles the mouse hover event to activate the main nav item and sub nav menus
   * @param {number} itemIndex
   */
  const handleHover = (itemIndex) => {
    // Don't use focus on small devices, that will be handled with onTouchEnd events
    !isMobile && setActiveIndex(itemIndex)
  }

  /**
   * Handles mouse click event and pressing the enter key
   * @param {object} e Synthetic click event
   * @param {number} clickedIndex Index of the clicked item
   */
  const handleClick = (e, clickedIndex) => {
    if (hasSubMenus) {
      return handleItemWithMenusClick(e, clickedIndex)
    }
    handleItemClick()
  }

  /**
   * Handles a click event on a main nav item that contains sub nav menus
   * @param {object} e Synthetic click event
   * @param {number} parentItemIndex Index of the clicked parent nav item
   */
  const handleItemWithMenusClick = (e, parentItemIndex) => {
    // Prevent default click behavior on the <a> tag
    e.preventDefault()

    // Clicking on the active main item closes its sub menu in the mobile nav
    if (isMobile && parentItemIndex === activeIndex) {
      return setActiveIndex()
    }
    // Clicking on a non-active main nav item activates it
    return setActiveIndex(parentItemIndex)
  }

  /**
   * Handles clicking on a main nav item that doesn't have sub nav menus
   * - scroll the window to the top for mobile view
   * - mobile nav should be dismissed
   * - active item should be cleared
   */
  const handleItemClick = () => {
    !isSsr && isMobile && window.scrollTo({ top: 0, behavior: 'instant' })
    setMobileNavOpen()
    setActiveIndex()
  }

  /**
   * Determines the expanded of condensed height of the mobile sub nav. Defaults
   * to auto on the server and in the desktop design viewport
   *
   * @returns {string} Height of the mobile sub nav or 'auto'
   */
  const getSubNavHeight = () => {
    let subNavHeight = 'auto'

    if (!isMobile || !expandedHeight) {
      return subNavHeight
    }

    subNavHeight = isActive ? `${expandedHeight}px` : `${condensedHeight}px`
    return subNavHeight
  }

  /**
   * Item title a tag wrapped with `next/link` whe the main item has a url prop
   */
  const getItemTitle = () => {
    let ItemTitle = (
      <>
        <div className={styles['main-nav-unnested-chevron']}>
          <Typography
            className={classNames(styles['main-nav-item-title'], 'font-medium lg:font-bold')}
            variant="bodyLg"
            mobileVariant="bodyLg"
            component="span"
            breakpoint="lg"
            responsive={true}
            content={label}
            data-nav="item-title"
          />
          {hasSubMenus && isMobile && (
            <span className={styles['main-nav-item-icon']}>
              <ChevronDown title={label} width={13} height={8} />
            </span>
          )}
        </div>
      </>
    )

    return url ? (
      <a
        href={url}
        aria-haspopup={hasSubMenus ? 'true' : 'false'}
        role="button"
        aria-expanded="false"
        onClick={(e) => {
          handleClick(e, itemIndex)
        }}
        ref={titleRef}
      >
        {ItemTitle}
      </a>
    ) : (
      <button
        aria-haspopup={hasSubMenus ? 'true' : 'false'}
        role="button"
        aria-expanded="false"
        className={styles['no-link-item']}
        onClick={(e) => {
          handleClick(e, itemIndex)
        }}
      >
        {ItemTitle}
      </button>
    )
  }

  /**
   * Memoized value to determine if the Main Item should be data-selected="true" by checking its
   * child menus and items for a url match with currentPathname.
   */
  const isSelectedParentItem = useMemo(() => {
    if (!subNavMenus || !Array.isArray(subNavMenus)) {
      return false
    }

    // Determine the index of the subMenu with a child item route match
    const selectedSubMenuIndex = subNavMenus.findIndex(({ menuItems = [] }) => {
      const subUrlMatch = menuItems
        .filter((item) => item)
        .findIndex(({ url }) => currentPathname.startsWith(url))
      return subUrlMatch >= 0
    })

    return selectedSubMenuIndex >= 0
  }, [currentPathname, subNavMenus])

  return (
    <li
      className={styles['main-nav-item']}
      data-item-index={itemIndex}
      data-active={isActive}
      data-selected={currentPathname === url || isSelectedParentItem}
      data-testid={testid}
      onMouseEnter={() => handleHover(itemIndex)}
      onMouseLeave={() => handleHover()}
    >
      {getItemTitle()}
      {hasSubMenus && (
        <div
          className={styles['sub-nav']}
          style={{ height: getSubNavHeight() }}
          ref={subNavRef}
          data-testid={testid ? `${testid}-subnav` : `header-menu-${itemIndex}-subnav`}
          data-nav="sub-nav-container"
        >
          {subNavMenus.map(({ menuTitle, menuItems }) => (
            <SubNavMenu
              currentPathname={currentPathname}
              items={menuItems.filter((item) => item)}
              key={menuTitle}
              mainNavLabel={label}
              title={menuTitle}
              disabledRoutes={disabledRoutes}
              disabledElements={disabledElements}
            />
          ))}
        </div>
      )}
    </li>
  )
}

MainNavItem.propTypes = {
  currentPathname: PropTypes.string.isRequired,
  disabledRoutes: PropTypes.array,
  itemIndex: PropTypes.number,
  label: PropTypes.string.isRequired,
  subNavMenus: PropTypes.array,
  testid: PropTypes.string.isRequired,
  url: PropTypes.string,
}
